import React from 'react';

// Components
import { Layout, Container, Info, Button } from '../components';

// Data
import data from '../data/pages/careers.json';

const Careers = () => {
  const content = data.content;

  return (
    <Layout pageContext={data}>
      <Container offsetHero>
        <Info
          {...content.info}
          styleType="dark"
          gutter={{ bottom: '8rem' }}></Info>
        <Info
          imagePath={content.opportunities.imagePath}
          title={content.opportunities.title}
          text={content.opportunities.text}>
          <ul>
            {content.opportunities.positions.map((position, key) => (
              <li key={key}>
                <h4>{position.title}</h4>
                <span>{position.location}</span>
              </li>
            ))}
          </ul>
          <div>
            <Button variant="outline" to={content.opportunities.cta.src}>
              {content.opportunities.cta.label}
            </Button>
          </div>
        </Info>
      </Container>
    </Layout>
  );
};

export default Careers;
